@use '../../../assets/scss/variables';

.dropdown.MuiButtonBase-root {
  min-width: auto;
  height: 50px;
  width: auto;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid variables.$dark-gold;
  color: variables.$dark-gold;
  box-shadow: none;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  [class^="icon-"] {
    font-size: 18px;
    margin-left: variables.$base*2;
  }

  &:hover {
    background-color: transparent;
    box-shadow: none;
  }

  .MuiPaper-root {
    border: 1px solid variables.$dark-gold;
    border-radius: 5px;
    font-size: 14px;
    font-family: 'Avenir Next', sans-serif;
  }
  .data {
    font-size: 14px;
    font-family: 'Avenir Next', sans-serif;
  }
  @media #{variables.$mobile} {
    position: fixed;
    right: 20px;
    min-width: 50px;
    top: auto;
    border-radius: 25px;
    padding: 0;
    margin: 0 !important;
    background-color: white;
    .data {
      display: none;
    }
    .icon-user {
      margin: 0;

    }
  }
}

.user-menu {
  .MuiButtonBase-root {
    font-size: 14px !important;
    font-family: 'Avenir Next', sans-serif;
  }
}