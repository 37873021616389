.section-wrapper-super-admin {
  padding-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  margin-top: 10px;

  .lateral-menu {
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 200px;
    height: fit-content;
  }

  .lateral-menu h2 {
    margin-bottom: 10px;
  }

  .lateral-menu ul {
    list-style-type: none;
    padding: 0;
  }

  .lateral-menu li {
    padding: 10px 0;
    cursor: pointer;
    color: #555;
  }

  .lateral-menu li:hover {
    color: #000;
  }

  .content-wrapper {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 250px;
    min-height: 169px;
    position: relative;
  }

  .step-circle {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #555;
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
  }

  .ellipsis {
    display: flex;
    align-items: center;
    font-size: 24px;
    margin: 0 10px;
  }

  button {
    background-color: #555;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
  }

  button:disabled {
    background-color: #999;
    cursor: not-allowed;
  }

  button:hover {
    background-color: #444;
  }

  .file-input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .file-input-wrapper input[type='file'] {
    display: none;
  }

  .file-input-label {
    display: inline-block;
    background-color: #555;
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .file-input-label:hover {
    background-color: #444;
  }

  .file-name {
    margin-bottom: 10px;
    font-size: 16px;
    color: #555;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .inactive {
    opacity: 0.5;
    pointer-events: none;
  }

  .new-step {
    background-color: #e0e0e0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 400px;
    min-height: 200px;
    position: relative;
  }

  .step-3-circle {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #555;
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
  }

  .reset-button {
    background-color: #d9534f;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
  }

  .reset-button:hover {
    background-color: #c9302c;
  }

  .additional-margin-top {
    margin-top: 20px;
  }

  .message {
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
  }

  .message.success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
  }

  .message.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
  }
}
