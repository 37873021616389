@use "../variables";

.MuiModal-root {
  .MuiBox-root {
    border: 1px solid variables.$dark-gold;
    max-width: 83%;
    max-height: 90%;
    overflow: auto;
    outline: none !important;
    border-radius: 5px;
  }
  .close {
    position: absolute;
    top: variables.$base*2;
    right: variables.$base*2;
    background-color: transparent;
    border: 0;
    font-size: 20px;
    color: variables.$text-dark;
    cursor: pointer;
    padding: 0;
  }
  h2 {
    margin-bottom: variables.$base*3;
  }
  .actions {
    display: flex;
    gap: 20px;
  }
  @media #{variables.$mobile} {
    .actions {
      flex-direction: column-reverse;
    }
    .close {
      top: variables.$base*2;
    }
  }
}