@use '../../../../assets/scss/variables';

.App {
  .edit-vehicle {
    .actions {
      display: flex;
      gap: variables.$base*3;
      margin-top: variables.$base*5;
    }
    .error {
      color: variables.$error;
      font-size: small;
    }
    @media #{variables.$mobile} {
      .actions {
        flex-direction: column-reverse;
      }
    }
  }
}