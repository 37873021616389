@use '../../../../assets/scss/variables';

.drawer-trigger {
  position: absolute !important;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  border: 0;
  color: variables.$dark-gold !important;
  padding: 0;
  height: 40px;
  span[class*="icon-"] {
    font-size: 24px;
  }
}

.MuiDrawer-root {
  top: 96px;
  &.hac-drawer {
    .MuiBackdrop-root {
      top: 96px;
      background-color: rgba(0, 0, 0, 0.25);
    }
    .MuiDrawer-paper {
      top: 96px;
      padding: variables.$base*5 variables.$base*2.5 variables.$base*5 variables.$base*3.75;
      box-shadow: 0 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14);
      width: variables.$base*40;
      box-sizing: border-box;
      .close-drawer {
        position: absolute;
        top: 10px;
        right: 10px;
        color: variables.$dark-gold;
        font-size: 24px;
      }
      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
          margin: 0;
          padding: variables.$base*2.5 variables.$base*2;
          font-size: 14px;
          text-transform: uppercase;
          color: variables.$text;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          letter-spacing: 2px;

          > span {
            display: flex;
            justify-content: space-between;
          }
          a {
            color: variables.$text;
            text-decoration: none;
          }
          [class*=" icon-"] {
            margin-left: auto;
          }
          ul {
            background-color: #F8F9F9;
            margin-top: variables.$base*2.5;

            li {
              font-size: 14px;
              text-transform: none;
              padding: variables.$base*1.25 variables.$base*2;
              letter-spacing: normal;
              &:hover {
                font-weight: 500;
              }
              a {
              }
            }
          }
        }
      }
    }
  }
}
