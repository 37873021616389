@use "../variables";

.App {
  section {
    &.contact-info {
      .inner {
        .retailer {
          padding-bottom: variables.$base*5;
          border-bottom: 1px solid #cfcdcb;
          margin-bottom: variables.$base*5;
          dl {
            margin: 0;
            padding: 0;
            display: flex;
            gap: 30px;
            flex-wrap: wrap;
            > div {
              flex: 1 1 calc(33% - 20px);
              &:first-child {
                flex-basis: 100%;
              }
            }
            dd, dt {
              margin: 0;
              padding: 0;
              font-size: 14px;
              color: #383531;
              font-weight: 500;
            }
            dt {
              color: #5E5952;
              margin-bottom: variables.$base;
              font-weight: normal;
            }
          }
        }
        h5 {
          margin-top: 0;
          margin-bottom: variables.$base*3;
        }

        .person {
          margin: 0 0 variables.$base*5;
          padding: variables.$base*2 variables.$base*3;
          background-color: #F8F9F9;
          dl {
            margin: 0;
            display: flex;
            gap: variables.$base*3 variables.$base*5;
            flex-wrap: wrap;
            background-color: #F8F9F9;
            > div {
              flex: 1 1 calc(50% - 20px);
              &:first-child {
                flex-basis: 100%;
              }
            }
            dd, dt {
              margin: 0;
              padding: 0;
              font-size: 14px;
              color: #383531;
              font-weight: 500;
            }
            dt {
              color: #5E5952;
              margin-bottom: variables.$base;
              font-weight: normal;
            }
          }
          .actions {
            justify-content: flex-start !important;
            margin-top: variables.$base*5;
            margin-bottom: 0;
            &:first-child {
              margin-top: 0;
            }
          }
        }

      }
    }
  }
  @media #{variables.$mobile} {
    section {
      &.contact-info {
        margin-top: 0;
        .inner {
          .retailer {
            dl {
              flex-direction: column;
              > div {

                &:first-child {

                }
              }

              dd, dt {

              }

              dt {

              }
            }
          }
          .person {
            margin-bottom: variables.$base*5;

            dl {
              margin: 0;
              padding: 0 !important;
              display: flex;
              gap: 30px;
              flex-wrap: wrap;
              background-color: #F8F9F9;
              padding: variables.$base*2 variables.$base*3;

              > div {
                flex: 0 0 100%;
              }
            }
          }
        }
        .actions {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
