@use '../../../../assets/scss/variables';

.box-confirmation {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 760px;
    height: 403px;
    text-align: center;
    padding: 4px;
    background-color: white;
    border-radius: 5px;
    overflow: hidden !important;

    .box-content {
        padding: 60px;

        #modal-modal-title {
            color: variables.$text;
        }

        #hac-name {
            font-weight: 500;
            margin-top: 35px;
        }

        #hac-email {
            line-height: 1px;
        }

        .modal-buttons {
            display: inline-flex;
            justify-content: space-between;
            padding: 40px;
            gap: 50px;
    
            .button-mod {
                width: 260px;
                padding: 16px;
                background: transparent;
                box-shadow: none;
            }

            .cancel-mod {
                color: variables.$text;
                border: 1px solid rgba($color: variables.$text-dark, variables.$alpha: 0.2);
            }

            .confirm-mod {
                background-color: variables.$dark-gold;
                color: white;
                border: 1px solid variables.$dark-gold;
            }
        }
    }
    @media #{variables.$mobile} {
        .box-content {
            padding: 20px !important;

            .modal-buttons {
                width: 100%;

                flex-direction: column;
                padding-left: 0 !important;
                padding-right: 0 !important;
                padding-bottom: 0 !important;


                gap: 10px !important;
                button {
                    width: auto !important;
                }
            }
        }
    }
}