@use '../../../../assets/scss/variables';

.App {
  .tab-content {
    display: none;

    &.active {
      display: flex;
    }

    .intro {
      display: flex;
      margin-bottom: variables.$base*5;

      .actions {
        margin-left: auto;
        display: flex;
      }
    }
  }

  .title {
    display: flex;

    .btn {
      font-size: 24px;
    }
  }

  section.profile {
    background-image: url("../../../../assets/img/bg-profile.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: right top;
    min-height: calc(100vh - 208px);

  }

  main.profile {
    .MuiCollapse-root {
      margin-bottom: variables.$base*7.5;
    }
  }

  .edit-vehicle {
    .actions {
      display: flex;
      gap: variables.$base*3;
      margin-top: variables.$base*5;
    }

    .error {
      color: variables.$error;
      font-size: small;
    }
  }
}