
$base: 8px;

/* Colores */
$light: #FFFFFF;

$bg-section: #F2EEEC;
$bg-section-light: #F8F9F9;
$bg-section-dark: #CFCDCB;
$bg-submenu: #E2D4CB;

$dark-gold: #5E5952;

$text: #383531;
$text-dark: #0C121C;
$text-light: #7e7f82;

$update: #D2000A;

$error: #ff0000;

/* Responsive */
$mobile: 'only screen and (max-width: 767px)';
$tablet: 'only screen and (min-width: 768px)  and (max-width: 1160px)';
$desktop: 'only screen and (min-width: 1161px)';

// Variable para el slider de beneftis cambiar tambien en beneficts.js buscando el nombre de esta variable ($custom-slider-beneficts)
$custom-slider-beneficts: 'only screen and (min-width: 1210px)';