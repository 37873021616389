@use '../../../../assets/scss/variables';

.wadsworth {
  background-color: variables.$bg-section-light;
  padding: variables.$base*3;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  z-index: 100;
  .inner {
    display: flex;
    justify-content: space-between;
    max-width: 1160px;
    margin: 0 auto;
    align-items: center;
    p {
      font-size: 22px;
      Line-height: 32px;
      white-space: nowrap;
      margin: 0;
    }
    button {
      flex-basis: 1px;
      white-space: nowrap;
      span[class*="icon-"] {
        display: none;
      }
    }
  }
  @media #{variables.$mobile} {
    .inner {
      button {
        border-radius: 50%;
        height: 50px;
        width: 50px;
        .text {
          display: none;
        }
        span[class*="icon-"] {
          display: inline-block;
          font-size: 20px;
        }
      }
    }
  }
}