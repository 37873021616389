@use "../variables";

.tabs {
  background-color: variables.$bg-submenu;
  white-space: nowrap;
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  > ul {
    max-width: 1160px;
    width: 100%;
    margin: 0 auto;
    height: variables.$base*5.75;
    padding: 0;
    list-style-type: none;
    display: flex;
    li {
      border-bottom: 2px solid transparent;
      height: variables.$base*5.75;
      display: flex;
      align-items: center;
      padding: 0;
      margin-right: variables.$base*3.75;
      box-sizing: border-box;
      span {
        color: variables.$text-light;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;
        cursor: pointer;
        font-size: 12px;
      }
      &.active {
        border-bottom-color: variables.$text;
        span {
          color: variables.$text-dark;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &.internal {
    background-color: white;
    margin-bottom: variables.$base*2;
    > ul {
      li {
        flex-basis: auto !important;
        span {
          padding: 0 variables.$base
        }
      }
    }
  }
  @media #{variables.$mobile}, #{variables.$tablet} {
    padding: 0 variables.$base*4;
  }
}