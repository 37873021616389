@use "../variables";

.MuiTable-root {
  font-size: 10px;
  position: relative;
  white-space: nowrap;
  &.user-table {
    white-space: normal;
    .MuiTableHead-root {
      .MuiTableRow-root {
        .MuiTableCell-root {
          border: 0;
          font-size: 14px;
          font-family: 'Aventir Next', sans-serif;
          font-weight: 600;
          color: #5E5952;
          padding: variables.$base;
          &:last-child {
            position: sticky;
            right: 0;
            z-index: 10;
            background-color: #FFFFFF;
          }
        }
      }
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        .MuiTableCell-root {
          border: 0;
          font-size: 14px;
          font-family: 'Aventir Next', sans-serif;
          padding: variables.$base;
          font-weight: 500;
          color: #383531;
          .profile {
            text-decoration: none;
            text-transform: uppercase;
            color: variables.$text;
            border: 0;
            background-color: transparent;
            cursor: pointer;
            span {
              margin-right: variables.$base;
              color: variables.$dark-gold;
              font-size: 11px;
              font-weight: 500;
              white-space: nowrap;
            }
            &:hover {
              span.link {
                text-decoration: underline;
              }
            }
          }
          &:first-child {
            padding-left: variables.$base*2;
          }
          &:last-child {
            padding: variables.$base variables.$base*2;
            position: sticky;
            right: 0;
            z-index: 10;
            background-color: variables.$light;


          }
        }
        &:nth-child(odd) {
          background-color: #F2EEEC;
          td:last-child {
            background-color: #F2EEEC;
            div:last-child {
              background-color: #F2EEEC;
            }
          }
        }
      }
    }
    .logged {
      font-weight: 500;
      color: #005A2B;
    }
    .icon-more {
      cursor: pointer;
    }
  }
}