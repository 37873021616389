.submenu {
  .MuiMenu-paper {
    box-shadow: none;
    border: 1px solid #5E5952;
    border-radius: 4px;
    font-family: 'Avenir Next', sans-serif;
    font-weight: normal;
    .MuiMenuItem-root {
      font-size: 14px;
      font-family: 'Avenir Next', sans-serif;
    }
  }
}