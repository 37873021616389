@use "../variables";


.MuiFormControlLabel-root {
  .MuiCheckbox-root {
    color: variables.$dark-gold !important;
  }

  &.MuiButtonBase-root {
    color: variables.$dark-gold;
  }


  &.Mui-checked {
    color: variables.$dark-gold !important;
  }

  .MuiCheckbox-root + .MuiTypography-root {
    font-size: 14px;
    font-family: 'Avenir Next', sans-serif;
  }

  &.Mui-disabled {
    .MuiFormControlLabel-label {
      &.Mui-disabled {
        color: variables.$dark-gold;

      }
    }
  }
}

