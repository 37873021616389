
@font-face {
  font-family: 'Aventir Next';
  src: url('../fonts/aventirnext-regular-webfont.woff2') format('woff2'),
  url('../fonts/aventirnext-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Aventir Next';
  src: url('../fonts/aventirnext-medium-webfont.woff2') format('woff2'),
  url('../fonts/aventirnext-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Aventir Next';
  src: url('../fonts/aventirnext-demi-webfont.woff2') format('woff2'),
  url('../fonts/aventirnext-demi-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}


@font-face {
  font-family: 'Land Rover Web';
  src: url('../fonts/landroverweb-demi-webfont.woff2') format('woff2'),
  url('../fonts/landroverweb-demi-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Land Rover Web';
  src: url('../fonts/landroverweb-bold-webfont.woff2') format('woff2'),
  url('../fonts/landroverweb-bold-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}