@use "sass:meta";
@forward 'variables';

@import-normalize;

@include meta.load-css('scaffolding');
@include meta.load-css('type');
@include meta.load-css('fonts');
@include meta.load-css('helpers');
@include meta.load-css ('../icons/style.css');

/* components */
@include meta.load-css('components/button');
@include meta.load-css('components/back-button');
@include meta.load-css('components/checkbox');
@include meta.load-css('components/contact-info');
@include meta.load-css('components/datepicker');
@include meta.load-css('components/form-control');
@include meta.load-css('components/input-text');
@include meta.load-css('components/lateral-menu');
@include meta.load-css('components/modal');
@include meta.load-css('components/multiselect');
@include meta.load-css('components/radio-button');
@include meta.load-css('components/select');
@include meta.load-css('components/list');
@include meta.load-css('components/submenu');
@include meta.load-css('components/tabs');
@include meta.load-css('components/textarea');
@include meta.load-css('components/tooltip');
@include meta.load-css('components/user-table');