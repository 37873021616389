@use "../variables";

.lateral-menu {
  h2 {
    border-bottom: 1px solid variables.$bg-section;
    padding-bottom: variables.$base*3;
    margin-bottom: variables.$base;
  }
  ul {
    background-color: #F8F9F9;
    list-style-type: none;
    margin: 0 variables.$base;
    padding: 0;
    li {
      font-size: 14px;
      text-transform: none;
      display: flex;
      a {
        padding: variables.$base variables.$base*2;
        width: 100%;
        text-decoration: none;
        color: variables.$dark-gold;
        &:hover {
          font-weight: 500;
        }
      }
      &.on {

        background-color: #F2EEEC;
      }
    }
  }
  @media #{variables.$mobile}, #{variables.$tablet} {
    display: none;
  }
}