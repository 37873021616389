@use '../../../../assets/scss/variables';

.App {
  main {
    section {
      &.error {
        .inner {
          max-width: 560px;
          text-align: center;
          .code {
            font-size: 64px;
            line-height: 56px;
            letter-spacing: 1.1px;
            text-transform: uppercase;
            color: #E2D4CB;
            margin-bottom: 24px;
          }
          h2 {
            margin-bottom: 24px
          }
        }
        .actions {
          max-width: 200px;
          margin: 0 auto;
        }
      }
    }
  }

}