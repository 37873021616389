@use '../../../../assets/scss/variables';

.App {
  main {
    section {
      .inner {
        header {
          display: flex;
          justify-content: space-between;
          margin-bottom: variables.$base*2;
          .btn {
            margin-left: auto;
            width: auto;
          }
        }
        .filters {
          .actions {
            align-items: center;
            display: flex;
            height: 100%;
            margin-top: 4px;
          }
        }
        .summary {
          margin-top: variables.$base*2;
          margin-bottom: 0;
          > span,
          > strong {
            font-size: 12px;
            margin: 0 variables.$base*3;
            &:first-child {
              margin-left: 0;
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  @media #{variables.$mobile} {
    main {
      section {
        .inner {
          .summary {
            margin-top: variables.$base*3;
          }
        }
      }
    }
  }
}