@use '../../../../assets/scss/variables';

.App {
  .activity-log {
    width: variables.$base*95;
    margin-left: auto;
    margin-right: auto;
    h2 + p {
      margin-bottom: variables.$base*2
    }
    .notification-item {
      font-size: 14px;
      background-color: #F8F9F9;
      padding: variables.$base*2.5;
      color: #5E5952;
      font-weight: normal;
      margin-bottom: variables.$base*2;
      header {
        display: flex;
        margin-bottom: variables.$base*0.5;
      }
      .text {
        color: variables.$text;
        font-weight: 700;
      }
      .date {
        font-size: 12px;
      }
    }


  }
}
