@use '../../../../assets/scss/variables';

.App {
  main.preferences {
    .inner {
      .header-img {
        margin-bottom: variables.$base*3;
        img {
          max-width: 100%;
        }
      }
      .actions {
        display: flex;
        [class^=icon-], [class*=" icon-"] {
          font-size: 16px;
        }
      }
    }
  }
}