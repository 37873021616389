@use '../../../../assets/scss/variables';

.App {
  main {
    .settings {
      h2 + p {
        margin-bottom: variables.$base*7.5;
      }
      .title {
        display: flex;
        .btn {
          font-size: 24px;
        }
      }
      .MuiCollapse-root {
        margin-bottom: variables.$base*7.5;
      }
      .form-control {
        max-width: 300px;
      }
      .consents {
        .form-control {
          max-width: none;
        }
      }
      .actions {
        .btn {
          display: inline-flex;
          width: auto;
          padding-left: variables.$base*4;
          padding-right: variables.$base*4;
        }
      }
    }
  }
}