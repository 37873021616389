@use '../../../../assets/scss/variables';

table.admin-retailer {
  td,
  th {
    span.text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    }
    &:nth-child(1) {
      span.text {
        max-width: 284px;
        width: 284px;
      }
    }
    &:nth-child(2),
    &:nth-child(3) {
      span.text {
        max-width: 160px;
        width: 160px;
      }
    }

    &:nth-child(4) {
      span.text {
        max-width: 265px;
        width: 265px;
      }
    }

    &:nth-child(5) {
      span.text {
        max-width: 144px;
        width: 144px;
      }
    }


  }
}