@use '../../../../assets/scss/variables';

table.admin-admins {
  td,
  th {
    span.text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    }
    &:nth-child(1),
    &:nth-child(2) {
      span.text {
        max-width: 136px;
        width: 136px;
      }
    }
    &:nth-child(3) {
      span.text {
        max-width: 160px;
        width: 160px;
      }
    }

    &:nth-child(4) {
      span.text {
        max-width: 204px;
        width: 204px;
      }
    }

/*    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
      span.text {
        max-width: 92px;
        width: 92px;
      }
    }*/


  }
}