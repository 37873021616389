@use '../../../assets/scss/variables';

.App {
  > footer {

    .inner {
      p {
        font-size: 12px;
        line-height: 100%;
      }
      ul {
        list-style-type: none;
        margin: 0 0 variables.$base*2 0;
        font-size: 14px;
        padding: 0;
        li {
          display: inline-block;
          margin-right: variables.$base*2;
          a {
            color: variables.$text-dark;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

    }
    @media #{variables.$tablet}, #{variables.$mobile} {
      > section {
        margin-top: 20px !important;
      }
      .inner {
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding: 0 variables.$base*2;
      }
    }
  }
}