@use '../../../../assets/scss/variables';

.App {
  main {
    &.legal {
      section {
        display: block;
        .inner {
          padding-top: variables.$base*4;
          padding-bottom: variables.$base*4;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}