@use '../../../../assets/scss/variables';

.preferences {
  .actions {
    span[class*=" icon-"] {
      font-size: 16px;
    }
  }
}
.hac-preferences {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    margin: 0;
    padding: 0;
    &:nth-child(even) {
      background-color: variables.$bg-section;
    }

    > span {
      display: flex;
      padding: variables.$base*1.5 variables.$base*2 variables.$base*1.5 variables.$base*3;
      justify-content: space-between;
      .title {
        font-weight: 500;
        font-size: 14px;
      }
    }
    .header {
      display: flex;
      white-space: nowrap;
      max-width: calc(100% - 50px);
      flex: 1 1 calc(100% - 50px);

      span {
        &:nth-child(2) {
          flex: 0 0 auto;
          margin-right: variables.$base*2;
        }
        &:nth-child(3) {
          flex: 1 1 auto;
          white-space: break-spaces;
        }
      }
    }
    p {
      font-size: 14px;
      color: variables.$dark-gold;
      padding: variables.$base*1.5 variables.$base*7.5 variables.$base*3;
    }
    span[class*="icon-"] {
      font-size: 16px;
      margin-right: variables.$base*2;
    }
  }
  .MuiMenu-paper {
    box-shadow: none;
  }
  @media #{variables.$mobile} {

      li {
        .header {
          flex-wrap: wrap;
          span:last-child {
            flex: 1 1 100%;
            padding-left: variables.$base*4
          }
        }
      }

  }
}