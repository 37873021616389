@use '../../../../assets/scss/variables';

.preferences {
  .actions {
    span[class*=" icon-"] {
      font-size: 16px;
    }
  }
}
.hac-preferences {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    margin: 0;
    padding: 0;
    background-color: variables.$bg-section;
    &:nth-child(even) {
      background-color: white;
    }

    > span {
      display: flex;
      padding: variables.$base*1.5 variables.$base*2 variables.$base*1.5 variables.$base*3;
      justify-content: space-between;
      span {
        display: flex;
      }
      .title {
        font-weight: 500;
        font-size: 14px;
      }
    }
    p {
      font-size: 14px;
      color: variables.$dark-gold;
      padding: variables.$base*1.5 variables.$base*7.5 variables.$base*3;
    }
    span[class*="icon-"] {
      font-size: 16px;
      margin-right: variables.$base*2;
    }
  }
  .MuiMenu-paper {
    box-shadow: none;
  }
}