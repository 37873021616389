@use "variables";

.txt-center {
  text-align: center;
}
.txt-left {
  text-align: left;
}

.hidden {
  display: none !important;
}

.disabled {
  opacity: 0.2;
}

.update {
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: variables.$update !important;
  border-radius: 50%;
  margin-left: variables.$base;
}

.MuiMenu-root {
  .MuiMenu-paper {
    li {
      span {
        font-size: 16px;
        margin-right: variables.$base*2;
      }
    }
  }
}