@use '../variables';

.btn {
  background-color: variables.$dark-gold;
  height: variables.$base*6.25;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 1px solid variables.$dark-gold;
  color: variables.$light;
  border-radius: variables.$base*0.5;
  width: 100%;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 3px;
  padding-left: variables.$base*2;
  padding-right: variables.$base*2;
  transition: color 0.4s,
  background-color 0.4s,
  box-shadow 0.4s,
  border-color 0.4s;
  font-size: 10px;
  font-family: 'Avenir Next', sans-serif;
  white-space: nowrap;
  font-weight: 600;

  &:hover {
    color: variables.$dark-gold;
    background-color: variables.$light;
    border-color: variables.$dark-gold
  }

  &:disabled {
    background-color: #E9ECEC;
    color: #c6c9cb;

    &:hover {
      background-color: #E9ECEC;
      color: #c6c9cb;
    }
  }

  &.secondary {
    background-color: white;
    border-color: variables.$text-dark;
    color: variables.$text-dark;
    &:hover {
    }
  }

  &.text {
    background-color: transparent;
    border: 0;
    color: variables.$text-dark;
    display: inline-flex;
    width: auto;
    margin-left: auto;

    &:hover {
    }
  }

  &.fit {
    width: auto;
    display: inline-block;
    white-space: break-spaces;
  }
  &.small {
    height: 40px;
  }
}