@use '../../../../assets/scss/variables';

.App {
  main.preferences {
    .inner {
      .actions {
        display: flex;
        [class^=icon-], [class*=" icon-"] {
          font-size: 16px;
        }
      }
    }
  }
}