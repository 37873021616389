@use '../../../../assets/scss/variables';

.App {
  main.delete-user {
    .inner {
      max-width: 760px;
    }

    h2 + p {
      margin-bottom: variables.$base*3
    }

    .MuiGrid2-root {
      &:last-child {
        strong {
          margin-bottom: 2px;
        }
      }
    }

    .data {
      display: flex;
      background-color: variables.$bg-section;
      padding: variables.$base*2;
      width: 100%;
      justify-content: flex-start;
      margin-right: auto;
      clear: both;
      flex-direction: column;
      margin-bottom: variables.$base*5;
      font-size: 14px;
      box-sizing: border-box;

      strong {
        display: inline-block;
        margin-bottom: variables.$base*3;
        font-weight: 500;
      }

      span {
        margin-bottom: variables.$base;
        display: block;
      }
    }

    .actions {
      display: flex;
      justify-content: flex-start;
      margin-right: auto;
    }

    p {
      margin-bottom: variables.$base*5;

      strong {
        font-weight: 500;
      }
    }
  }
}
@keyframes rainbow_animation {
  0%,100% {
    background-position: 0 0;
  }

  50% {
    background-position: 100% 0;
  }
}
