@use '../../../../assets/scss/variables';

.App {
  main.unenroll {
    h2 + p {
      margin-bottom: variables.$base*2
    }
    .data {
      display: flex;
      background-color: variables.$bg-section;
      padding: variables.$base*2;
      width: auto;
      justify-content: flex-start;
      margin-right: auto;
      clear: both;
      flex-direction: column;
      margin-bottom: variables.$base*3;
      strong {
        margin-bottom: variables.$base;
        display: inline-block;
      }
    }
    .actions {
      display: flex;
      justify-content: flex-start;
      margin-right: auto;
    }

  }
}
