@use '../variables';

.back-button {
  font-size: 10px;
  text-decoration: none;
  letter-spacing: 2px;
  color: variables.$text;
  text-transform: uppercase;
  margin-bottom: variables.$base*5;
  margin-top: variables.$base*5;
  display: inline-block;
  align-items: center;
  font-weight: 600;
  a {
    margin: 0;
  }
}