@use '../variables';

select {
  -webkit-appearance: none;
  font-family: 'Avenir Next', sans-serif;
  background-image: url("../../../assets/img/drop-down.png");
  background-repeat: no-repeat;
  background-position: right 15px top 20px;
  line-height: 20px;
  &.normal {
    border-radius: variables.$base*0.5;
    font-size: 14px;
    padding: variables.$base*1.75 variables.$base*1.25;
    border: 1px solid variables.$dark-gold;
    box-sizing: border-box;
    width: 100%;
  }
  &.language-switcher {
    border-radius: variables.$base*0.5;
    font-size: 14px;
    padding: variables.$base*2 variables.$base*4.5 variables.$base*2 variables.$base*2;
    border: 1px solid variables.$dark-gold;

  }
  &:disabled {
    background-color: #f1f1f1;
    opacity: 1;
    color: variables.$dark-gold;
  }
}
