@use '../variables';

.MuiTooltip-tooltip {
  font-size: 14px;
  padding: variables.$base variables.$base*2 variables.$base*0.75 !important;
  background-color: variables.$light !important;
  border: 1px solid variables.$text;
  color: variables.$text !important;
  position: relative;
  display: inline-block !important;
  .MuiTooltip-arrow {
    color: variables.$text;
    &:before {
      all: unset;
      content: '';
      display: block;
      width: 0;
      height: 0;
      border: 9px solid transparent;
      border-top-color: variables.$text;
      position: absolute;
      top: -3px;
      left: 30%;
      transform: translateX(-40%);
    }
    &:after {
      all: unset;
      content: '';
      display: block;
      width: 0;
      height: 0;
      border: 8px solid transparent;
      border-top-color: variables.$light;
      position: absolute;
      top: -3px;
      left: 30%;
      transform: translateX(-40%);
    }
  }
}
