@use '../../../../assets/scss/variables';

table.pma-hac {
  td,
  th {
    span.text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      .MuiSvgIcon-root {
        opacity: 0.5 !important;
        path {
          opacity: 0.5 !important;
        }
      }
    }

    &:nth-child(1),
    &:nth-child(2) {
      span.text {
        max-width: 136px;
        width: 136px;
      }
    }

    &:nth-child(3) {
      span.text {
        max-width: 194px;
        width: 194px;
      }
    }

    &:nth-child(4),
    &:nth-child(5) {
      span.text {
        max-width: 160px;
        width: 160px;
      }
    }

    &:nth-child(6) {
      span.text {
        max-width: 180px;
        width: 180px;
      }
    }
  }
}