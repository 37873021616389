@use "../variables";

.MuiRadioGroup-root {
  .MuiFormControlLabel-root {
    .MuiButtonBase-root {
      color: variables.$dark-gold;
    }
  }
  .MuiTypography-root {
    font-family: 'Avenir Next', sans-serif;
    font-size: 14px;
  }
}