@use '../../../../assets/scss/variables';

.App {
  main {
    .contact-info {
      &.admin {
        .person {
          dl {
            margin-bottom: variables.$base*3;
          }
        }
        .actions {
          justify-content:flex-end;
        }
      }
    }
  }
}