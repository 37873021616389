@use '../../assets/scss/variables';

.input {
  &__error {
    color: variables.$error;
    font-size: small;
    line-height: 110%;
  }
  &_label {
    display: block;
    font-size: 12px;
    margin-bottom: variables.$base;
    &__error {
      color: variables.$error;
    }
  }
}